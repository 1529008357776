/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import "./layout.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}


    render={data => {
      console.log(this);
      return (
        <>
          <div
            style={{
              margin: `0 auto`,
              padding: `0px`,
              paddingTop: 0,
              maxHeight: '100%',
            }}
          >
            <main>{children}</main>
          </div>
        </>
      )
    }
  }
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
